import './App.css';

const RESUME_VIEW_URL = 'https://github.com/desmondw/resume/blob/master/Resume%20-%20Desmond%20Weindorf.pdf'
const RESUME_DOWNLOAD_URL = 'https://raw.githubusercontent.com/desmondw/resume/master/Resume%20-%20Desmond%20Weindorf.pdf'
const SOCIAL = [
  // { alt: 'Twitter', img: '/img/twitter.png', url: 'https://twitter.com/DesmondWeindorf', blank: true },
  { alt: 'GitHub', img: '/img/github.png', url: 'https://github.com/desmondw', blank: false },
  { alt: 'LinkedIn', img: '/img/linkedin.png', url: 'https://www.linkedin.com/in/desmondweindorf', blank: false },
  // { alt: 'Resume', img: '/img/resume.png', url: RESUME_VIEW_URL, blank: false },
  { alt: 'Email', img: '/img/email.png', url: 'mailto:hello@desmondw.com', blank: true },
  { alt: 'Jeopardy!', img: '/img/podium.png', url: 'http://polywots.com/?host', blank: true },
];

function App() {
  const menuItems = [
    SOCIAL.map(v=>(
      <span className="social-item" key={`social-${v.alt}`}>
        <a href={v.url} target={v.blank ? "_blank" : ""} rel="noreferrer">
          <img className="social-item-img" src={v.img} title={v.alt} alt={v.alt} />
        </a>
      </span>
    )),
  ];

  return (
    <div className="App">
      <div>
          <div><img className="headshot" src="/img/profile.jpg" alt="headshot" /></div>
          <h1 className="name">Desmond Weindorf</h1>
          <div className="blurb">Software Engineer</div>
          <div className="lfw">
            Résumé:&nbsp;
            <span className="lfw-subtext">
              <a href={RESUME_VIEW_URL} rel="noreferrer">view</a> or <a href={RESUME_DOWNLOAD_URL} rel="noreferrer">download</a>
            </span>
          </div>
          <div className="menu-items">
            <div className="menu-item">{menuItems}</div>
          </div>
      </div>
    </div>
  );
}

export default App;
